<section id="home" class="sm-py bg-gray1 bb-1 border-gray1 fullwidth">
  <!-- Container -->
  <div class="container">
    <div class="row calculate-height">
      <div class="t-left t-center-xs col-sm-6 col-xs-12">
        <h2 class="normal-subtitle">Course</h2>
        <h5 class="gray7">Learn 'n' practice, never get outdated...</h5>
      </div>
      <div class="t-right t-center-xs col-sm-6 col-xs-12">
        <ol class="breadcrumb transparent no-pm v-center font-12">
          <li class="breadcrumb-item black-hover gray8 slow"><a routerLink="/home">Home</a></li>
          <li class="breadcrumb-item gray6 c-default">Course</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- End Container -->
</section>

<section id="elements" class="py lh-lg gray8">

  <!-- Container for filters -->
  <div class="container">

    <!-- Filters Category -->
    <div id="filters" class="cbp-l-filters-text t-center animated fadeInUp" data-animation="fadeInUp"
      data-animation-delay="0">
      <div data-filter="*" class="cbp-filter-item-active cbp-filter-item">
        <span class="underline-dashed font-16">All </span>
        <div class="cbp-filter-counter"></div>
      </div>
      <div data-filter=".design" class="cbp-filter-item">
        <span class="underline-dashed font-16">Design</span>
        <div class="cbp-filter-counter"></div>
      </div>
      <div data-filter=".analysis" class="cbp-filter-item">
        <span class="underline-dashed font-16">Analysis</span>
        <div class="cbp-filter-counter"></div>
      </div>
    </div>

  </div>
  <!-- End Container for filters -->

  <!-- Container for element list -->
  <div class="container sm-mt">


    <div id="items" class="cbp fadeInUp">

      <!-- Box -->
      <a [routerLink]="['/course']" routerLinkActive="router-link-active" class="cbp-item design">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="100">
            <!-- Mark -->
            <div class="basic-mark bold uppercase font-10 radius bg-danger white">
              Hot!
            </div>
            <h4 class="bold-subtitle">AutoCAD</h4>
            <p class="gray7 xxs-mt">AutoCAD® is computer-aided design (CAD) software that architects, engineers and
              construction professionals rely on to create precise 2D and 3D drawings.</p>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/course']" routerLinkActive="router-link-active" class="cbp-item analysis">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="50">
            <!-- Mark -->
            <div class="basic-mark bold uppercase font-10 radius bg-danger white">
              Hot!
            </div>
            <h4 class="bold-subtitle">CFD</h4>
            <p class="gray7 xxs-mt">CFD is a computational fluid dynamics simulation software that engineers and
              analysts use to intelligently predict how liquids and gases will perform.</p>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/course']" routerLinkActive="router-link-active" class="cbp-item analysis">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="100">
            <h4 class="bold-subtitle">Structure</h4>
            <p class="gray7 xxs-mt">Structural analysis is the determination of the effects of loads on physical
              structures and their components. Structures subject to this type of analysis include all that must
              withstand loads, such as buildings, bridges, aircraft and ships. ... Structural analysis is thus a key
              part of the engineering design of structures.</p>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/course']" routerLinkActive="router-link-active" class="cbp-item analysis">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="150">
            <h4 class="bold-subtitle">Vibration</h4>
            <p class="gray7 xxs-mt">Vibration analysis involves using a vibration sensitive transducer and
              instrumentation to measure and record the vibration characteristic of a rotating machine.</p>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/course']" routerLinkActive="router-link-active" class="cbp-item design">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="50">
            <h4 class="bold-subtitle">Catia</h4>
            <p class="gray7 xxs-mt">CATIA is the World's Leading Solution for Product Design and Experience. It is used
              by leading organizations in multiple industries to develop the products we see and use in our everyday
              lives.</p>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/course']" routerLinkActive="router-link-active" class="cbp-item design">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="100">
            <h4 class="bold-subtitle">Solidworks</h4>
            <p class="gray7 xxs-mt">Dassault Systèmes SOLIDWORKS Corp. develops and markets 3D CAD design software,
              analysis software, and product data management software.</p>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/course']" routerLinkActive="router-link-active" class="cbp-item design">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="150">
            <h4 class="bold-subtitle">Creo</h4>
            <p class="gray7 xxs-mt">Creo is the industry's leading 3D CAD software. Try Creo Parametric for free today
              and start advancing your design capabilities.</p>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/course']" routerLinkActive="router-link-active" class="cbp-item design">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="50">
            <!-- Mark -->
            <div class="basic-mark bold uppercase font-10 radius bg-info white">
              New!
            </div>
            <h4 class="bold-subtitle">Unigraphics</h4>
            <p class="gray7 xxs-mt">NX, formerly known as "unigraphics", is an advanced high-end CAD/CAM/CAE, which has
              been owned since 2007 by Siemens PLM Software. Siemens NX software is a flexible and powerful integrated
              solution that helps you deliver better products faster and more efficiently.</p>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/course']" routerLinkActive="router-link-active" class="cbp-item design">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="50">
            <!-- Mark -->
            <div class="basic-mark bold uppercase font-10 radius bg-info white">
              New!
            </div>
            <h4 class="bold-subtitle">ANSYS Workbench</h4>
            <p class="gray7 xxs-mt">The ANSYS Workbench platform is the backbone for delivering a comprehensive and integrated simulation system. Using Workbench for your product development simulations will result in higher productivity from integrated applications and access to multiphysics and systems level capabilities.</p>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a
        [routerLink]="['/course']"
        routerLinkActive="router-link-active"
        class="cbp-item design"
      >
        <div class="qdr-hover-4 block">
          <div
            class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2"
            data-animation="fadeInUp"
            data-animation-delay="50"
          >
            <!-- Mark -->
            <div class="basic-mark bold uppercase font-10 radius bg-info white">
              New!
            </div>
            <h4 class="bold-subtitle">Aerospace Technical Publication</h4>
            <p class="gray7 xxs-mt">
              Unlock your potential with our
              <b>Aerospace Technical Publication</b> Course, meticulously
              designed to equip you with essential skills in creating, managing,
              and delivering accurate technical documents for the aerospace
              industry. From crafting detailed <b>aircraft manuals</b> to
              designing <b>technical illustrations</b>, this course ensures you
              master global industry standards like <b>ATA iSpec 2200</b> and
              <b>S1000D</b>, setting you on a path to excel in aerospace
              documentation and technical authoring.
            </p>
          </div>
        </div>
      </a>

    </div>

  </div>



</section>
